export default {
  cguExclusion: {
    id: 'modals_warranty_cgu_exclusion',
    defaultMessage:
      'Des exclusions de garantie s’appliquent. Pour en savoir plus sur les conditions et exclusions de garantie commerciale, rendez-vous {link, html}.',
  },
  cguExclusionLink: {
    id: 'modals_warranty_cgu_exclusion_link',
    defaultMessage: 'ici',
  },
  eula: {
    id: 'modals_warranty_cgu_new',
    defaultMessage:
      'This warranty against breakdown does not preclude the application of the legal warranty of conformity and/or the warranty of hidden defects. To find out more, {link, html}.',
  },
  eulaLink: {
    id: 'modals_warranty_cgu_new_link',
    defaultMessage: 'visit here',
  },
}
