<template>
  <RevModal :name="name" :title="title" variant="panel">
    <template #body>
      <div class="mb-32">
        <RevIllustration
          alt=""
          class="w-full"
          :height="137"
          src="/img/reassurance/warranty-modal.svg"
          :width="327"
        />
      </div>
      <WarrantyModalContent />
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import { type Features } from '~/features'

import translations from './WarrantyModal.translations'
import WarrantyModalContent from './WarrantyModalContent.vue'

defineProps<{
  name: string
}>()

const i18n = useI18n()
const { features } = useMarketplace<Features>()

const title = computed(() => {
  return i18n(translations.title, {
    warrantyDuration: features.warranty.duration,
  })
})
</script>
