<template>
  <div>
    <div class="mb-24 flex items-start">
      <IconThumbsDownOutlined class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.expectationTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.expectationTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>
        <span class="body-2">
          {{
            i18n(translations.expectationDescription, {
              days: coolingOffDays,
            })
          }}
        </span>
      </div>
    </div>

    <div v-if="i18n(translations.returnTitle)" class="mb-24 flex items-start">
      <IconClock class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.returnTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.returnTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>
        <span class="body-2">
          {{ i18n(translations.returnDescription) }}
        </span>
      </div>
    </div>

    <div v-if="i18n(translations.paymentTitle)" class="mb-24 flex items-start">
      <IconRefund class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.paymentTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.paymentTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>
        <span class="body-2">
          {{ i18n(translations.paymentDescription) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { IconClock } from '@ds/icons/IconClock'
import { IconRefund } from '@ds/icons/IconRefund'
import { IconThumbsDownOutlined } from '@ds/icons/IconThumbsDownOutlined'

import { type Features } from '~/features'

import translations from './SatisfactionModal.translations'

const i18n = useI18n()
const { features } = useMarketplace<Features>()
const { coolingOffDays } = features
</script>
