<template>
  <RevModal :name="name" :title="title" variant="panel">
    <template #body>
      <div class="mb-32">
        <RevIllustration
          alt=""
          class="w-full"
          :height="137"
          src="/img/reassurance/satisfaction-modal.svg"
          :width="327"
        />
      </div>
      <SatisfactionModalContent />
    </template>
  </RevModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import { type Features } from '~/features'

import translations from './SatisfactionModal.translations'
import SatisfactionModalContent from './SatisfactionModalContent.vue'

const i18n = useI18n()
const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

defineProps<{
  name: string
}>()

const title = computed(() => {
  return i18n(translations.title, {
    days: coolingOffDays,
  })
})
</script>
