<template>
  <div>
    <div v-if="i18n(translations.pointOneTitle)" class="mb-24 flex items-start">
      <IconSiren class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.pointOneTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.pointOneTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>

        <span v-if="isGermany" class="body-2">
          <FormattedMessage :definition="translations.pointOneDescription">
            <template #link>
              <RevLink target="_blank" :to="warrantyLocation">
                {{ i18n(translations.pointOneDescriptionLink) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </span>

        <span v-if="!isGermany" class="body-2">
          {{ i18n(translations.pointOneDescription) }}
        </span>
      </div>
    </div>

    <div v-if="i18n(translations.pointTwoTitle)" class="mb-24 flex items-start">
      <IconClock class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.pointTwoTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.pointTwoTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>
        <span class="body-2">
          {{ i18n(translations.pointTwoDescription) }}
        </span>
      </div>
    </div>

    <div
      v-if="i18n(translations.pointThreeTitle)"
      class="mb-24 flex items-start"
    >
      <IconTools class="shrink-0" />
      <div class="ml-12">
        <h6 class="body-1-bold mb-8">
          <!-- TODO [BROW-1277] Remove FormattedMessage and highlight once all languages are translated -->
          <FormattedMessage :definition="translations.pointThreeTitle">
            <template #highlight>
              <span>
                {{ i18n(translations.pointThreeTitleHighlight) }}
              </span>
            </template>
          </FormattedMessage>
        </h6>
        <span class="body-2">
          {{ i18n(translations.pointThreeDescription) }}
        </span>
      </div>
    </div>
    <WarrantyExclusion />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevLink } from '@ds/components/Link'
import { IconClock } from '@ds/icons/IconClock'
import { IconSiren } from '@ds/icons/IconSiren'
import { IconTools } from '@ds/icons/IconTools'

import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'
import WarrantyExclusion from '~/scopes/product/components/WarrantyExclusion/WarrantyExclusion.vue'

import translations from './WarrantyModal.translations'

const {
  market: { countryCode },
} = useMarketplace()
const i18n = useI18n()

const warrantyLocation = {
  name: CMS.LEGAL_PAGE,
  params: {
    pageName: LEGAL_PAGE_NAME.WARRANTY,
  },
}
const isGermany = computed(() => {
  return countryCode === MarketCountryCode.DE
})
</script>
