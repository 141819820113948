export default {
  title: {
    id: 'reassurance_service_warranty',
    defaultMessage: '{warrantyDuration}-year minimum warranty',
  },
  pointOneTitle: {
    id: 'modals_warranty_point_one_title_new',
    defaultMessage: 'We gotchu on defects for the first year',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  pointOneTitleHighlight: {
    id: 'modals_warranty_point_one_title_new_highlight',
    defaultMessage: 'a defect',
  },
  pointOneDescription: {
    id: 'modals_warranty_point_one_description',
    defaultMessage:
      'If your item has a technical defect within the first year, we’ll repair or replace it on our dime. {link, html}',
  },
  pointOneDescriptionLink: {
    id: 'modals_warranty_point_one_description_link',
    defaultMessage: 'See warranty conditions.',
  },
  pointTwoTitle: {
    id: 'modals_warranty_point_two_title_new',
    defaultMessage: 'Ship it back to the seller for free',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  pointTwoTitleHighlight: {
    id: 'modals_warranty_point_two_title_new_highlight',
    defaultMessage: '24 hours',
  },
  pointTwoDescription: {
    id: 'modals_warranty_point_two_description',
    defaultMessage:
      'We even cover the shipping costs to get your item to the seller. Wheeeee easy returns!',
  },
  pointThreeTitle: {
    id: 'modals_warranty_point_three_title_new',
    defaultMessage: 'Get a repair or replacement on us',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  pointThreeTitleHighlight: {
    id: 'modals_warranty_point_three_title_new_highlight',
    defaultMessage: 'happy ending',
  },
  pointThreeDescription: {
    id: 'modals_warranty_point_three_description_new',
    defaultMessage:
      'Your item will be repaired or replaced (if a repair isn’t possible) free of charge. Really.',
  },
}
