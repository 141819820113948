export default {
  expectationTitle: {
    id: 'modals_satisfaction_point_one_title_new',
    defaultMessage: 'Just not that into your new device?',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  expectationTitleHighlight: {
    id: 'modals_satisfaction_point_one_title_new_highlight',
    defaultMessage: 'doesn’t meet your expectations',
  },
  expectationDescription: {
    id: 'modals_satisfaction_point_one_description_days',
    defaultMessage:
      'Return your item for any reason within the first {days} days of receiving it. All without having to say “it’s not you, it’s me.”',
  },
  returnTitle: {
    id: 'modals_satisfaction_point_two_title_new',
    defaultMessage: 'Returns are suuuper easy',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  returnTitleHighlight: {
    id: 'modals_satisfaction_point_two_title_new_highlight',
    defaultMessage: 'reimburses you',
  },
  returnDescription: {
    id: 'modals_satisfaction_point_two_description',
    defaultMessage:
      "Just log in to your Back Market account, go to “My orders”, click on “Ask for help”, follow some steps, and... that's it.",
  },
  paymentTitle: {
    id: 'modals_satisfaction_point_three_title_new',
    defaultMessage: 'Be reunited with your cash',
  },
  // TODO [BROW-1277] Remove unused highlight translation once all languages are translated
  paymentTitleHighlight: {
    id: 'modals_satisfaction_point_three_title_new_highlight',
    defaultMessage: 'reimburses you',
  },
  paymentDescription: {
    id: 'modals_satisfaction_point_three_description',
    defaultMessage:
      'You’re original payment method will be refunded within 3 business days.',
  },
  title: {
    id: 'reassurance_cooling_off_days',
    defaultMessage: '{days}-day money back guarantee',
  },
}
