<template>
  <div v-if="cgvModalLegals" class="body-2" data-test="modal-terms">
    <RevDivider class="my-16" />

    <p v-if="showWarrantyExclusionLine">
      <FormattedMessage :definition="translations.cguExclusion">
        <template #link>
          <RevLink target="_blank" :to="warrantyLocation" :underlined="true">
            {{ i18n(translations.cguExclusionLink) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>

    <p>
      <FormattedMessage :definition="translations.eula">
        <template #link>
          <RevLink target="_blank" :to="termsLocation" :underlined="true">
            {{ i18n(translations.eulaLink) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevDivider } from '@ds/components/Divider'
import { RevLink } from '@ds/components/Link'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import {
  LOCALES_WITH_WARRANTY_EXCLUSION,
  TERMS,
} from './WarrantyExclusion.constant'
import translations from './WarrantyExclusion.translations'

const {
  features: { cgvModalLegals },
  market: { countryCode },
} = useMarketplace()
const i18n = useI18n()
const locale = useI18nLocale()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const showWarrantyExclusionLine = computed(() => {
  return LOCALES_WITH_WARRANTY_EXCLUSION.includes(locale)
})

const termsLocation = computed(() => {
  return resolveLocalizedRoute(
    TERMS[countryCode] || {
      name: CMS.LEGAL_PAGE,
      params: {
        pageName: LEGAL_PAGE_NAME.TERMS_USE,
      },
    },
  )
})

const warrantyLocation = computed(() => {
  return resolveLocalizedRoute({
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.WARRANTY,
    },
  })
})
</script>
