import { MarketCountryCode } from '@backmarket/http-api'

import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

export const LOCALES_WITH_WARRANTY_EXCLUSION = ['fr-fr', 'fr-be']

type Link = {
  name: string
  params: Record<string, string>
}

export const TERMS: Partial<Record<MarketCountryCode, Link>> = {
  [MarketCountryCode.FR]: {
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SALE,
    },
  },
  [MarketCountryCode.BE]: {
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SALE,
    },
  },
  [MarketCountryCode.PT]: {
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SALE,
    },
  },
  [MarketCountryCode.US]: {
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.TERMS_SERVICE,
    },
  },
}
